.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
}

input, textarea {
  margin: 10px 0;
  height: 39px;
  width: 100%;
  max-width: 400px;
}

button {
  margin: 10px 0;
  height: 39px;
  width: 100%;
  max-width: 400px;
}

textarea {
  height: 200px;
}

.console {
  color: white;
  background-color: black;
  font-family: monospace;
}
